import styled from 'styled-components';
import { TLabel, TLinkLine, TRangeBall } from './SentimentScorePopover.types';

export const ScaleWrapper = styled.div`
  max-width: 445px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 18px;
  min-width: 265px;

  @media (max-width: 305px) {
    width: 265px;
  }
`;

export const RangeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RangeBall = styled.div<TRangeBall>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color = '#ffffff' }) => color};
  border-radius: 100%;
  height: 28px;
  width: 28px;
  border: 2px solid #ffffff;
  background-color: ${({ background }) => background};
  font-weight: 400;
  span {
    font-family: Avenir;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media (max-width: 305px) {
    height: 25px;
    width: 25px;
  }

  @media (min-width: 400px) {
    height: 26px;
    width: 26px;
  }

  @media (min-width: 430px) {
    height: 26px;
    width: 26px;
  }
`;

export const LinkingLine = styled.div<TLinkLine>`
  height: 5px;
  min-width: 1.3rem;
  background: linear-gradient(90deg, ${({ from }) => from}, ${({ to }) => to});

  @media (max-width: 305px) {
    min-width: 0.6rem;
  }
`;

export const Container = styled.div``;

export const Label = styled.span<TLabel>`
  text-align: justify;
  font-size: 12px;
  position: absolute;
  width: 60px;
  bottom: -3px;

  color: #4a4a4a;
  font-family: Avenir;
  font-style: normal;
  font-weight: 300;
  white-space: nowrap;
  line-height: normal;

  &.last {
    right: 0;
  }

  @media (max-width: 305px) {
    font-size: 11px;
  }
  /* Media query for screens with a width of 396 pixels and a height of 600 pixels or more */
  @media (min-width: 400px) {
    font-size: 11px;
  }

  /* Media query for screens with a width of 423 pixels and a height of 600 pixels or more */
  @media (min-width: 430px) {
    font-size: 11px;
  }
`;
